.stratos-datatable table {
  @apply w-full;
}

.stratos-datatable .default table th {
  @apply bg-components-light dark:bg-dark-components-light px-4 py-2 text-left text-sm font-semibold text-gray-900 lg:table-cell;
}

.stratos-datatable .compact table th {
  @apply text-primaryText dark:text-dark-primaryText bg-components-light dark:bg-dark-components-light px-2 py-1 text-left text-xs font-semibold lg:table-cell;
}

.stratos-datatable table th:first-of-type {
  @apply rounded-tl-md;
}

.stratos-datatable table th:last-of-type {
  @apply rounded-tr-md;
}

.stratos-datatable table tbody {
  @apply bg-white;
}

.stratos-datatable table tbody tr {
  /* @apply transition duration-300 ease-in-out hover:drop-shadow-md; */
  @apply transition duration-300 ease-in-out;
}

.stratos-datatable table tbody tr:hover td {
  @apply bg-buttons-tertiary-bg-hover dark:bg-dark-buttons-tertiary-bg-hover !important;
}

.stratos-datatable .default table td {
  @apply px-3 py-4 text-sm text-gray-600;
}

.stratos-datatable .default table tr.selected td,
.stratos-datatable .compact table tr.selected td,
.stratos-datatable.zebraStripes .default table tr.selected td,
.stratos-datatable.zebraStripes .compact table tr.selected td {
  @apply bg-blue-100;
}

.stratos-datatable .default table tr.expanded td,
.stratos-datatable .compact table tr.expanded td,
.stratos-datatable.zebraStripes .default table tr.expanded td,
.stratos-datatable.zebraStripes .compact table tr.expanded td {
  @apply bg-blue-100;
}

.stratos-datatable .compact table td {
  @apply text-primaryText dark:text-dark-primaryText px-2 py-1 text-xs;
}

.stratos-datatable table tr:last-of-type td:first-of-type {
  @apply rounded-bl-md;
}

.stratos-datatable table tr:last-of-type td:last-of-type {
  @apply rounded-br-md;
}

.stratos-datatable table td,
.stratos-datatable table th {
  @apply break-words;
  hyphens: auto;
}

.stratos-datatable tbody > tr td:first-child {
  @apply border-light dark:border-dark-light border-l;
}

.stratos-datatable tbody > tr td:last-child {
  @apply border-light dark:border-dark-light border-r;
}

.stratos-datatable tbody > tr:last-child td {
  @apply border-light dark:border-dark-light rounded-b-md border-b;
}

/*
 ** Styles For Zebra Striping Rows
*/
.stratos-datatable.zebraStripes table tr:nth-child(even) td {
  @apply dark:bg-dark-components-lighter bg-components-lighter;
}

.stratos-datatable.zebraStripes table tr:nth-child(odd) td {
  @apply bg-container-light dark:bg-dark-container-light;
}

/*
 ** Styles For Column Resizing on Tables
*/
.stratos-datatable table.border-collapse th,
.stratos-datatable table.border-collapse td {
  @apply relative;
}

.stratos-datatable table.border-collapse .resizer {
  @apply absolute right-0 top-0 h-full w-2 cursor-col-resize touch-none select-none bg-gray-300;
}

.stratos-datatable table.border-collapse .resizer.isResizing {
  @apply bg-blue-500 opacity-100;
}

.stratos-datatable .compact table th svg {
  @apply fill-gray-200;
}

.stratos-datatable .compact table th svg .fa-primary {
  @apply fill-blue-600;
}

.stratos-datatable .compact table th svg .fa-secondary {
  @apply fill-gray-200;
}

@media (hover: hover) {
  .resizer {
    opacity: 0;
  }

  *:hover > .resizer {
    opacity: 1;
  }
}
