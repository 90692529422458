/* React Datepicker style overrides */

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__input-container
  input {
  @apply block h-10 w-full rounded-md border border-gray-500  bg-white px-3 py-1 text-gray-700 shadow-sm focus-within:border-blue-500 focus-within:ring-blue-500  sm:text-sm;
}

div[data-eco-component="advanced-datepicker"].invalid
  .react-datepicker__input-container
  input {
  @apply border-red-300 focus:border-red-500 focus:ring-red-500;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker {
  @apply w-full border-0;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__header {
  @apply bg-components-baseComponent dark:bg-dark-components-baseComponent border-b-0 p-0;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__navigation {
  @apply bg-components-baseComponent dark:bg-dark-components-baseComponent dark:text-dark-primaryText text-primaryText rounded border-2 border-gray-500 transition hover:bg-gray-200;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation-icon::before {
  @apply border-buttons-tertiary-text dark:border-dark-buttons-tertiary-text;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation-icon--next {
  @apply left-0;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation--next {
  @apply right-3;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation-icon--previous {
  @apply right-0;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation--previous {
  @apply left-3;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker-right {
  @apply absolute left-auto right-0 top-11 transform-none !important;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__month-container {
  @apply bg-components-baseComponent dark:bg-dark-components-baseComponent flex w-full flex-col;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__month {
  @apply flex flex-col;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__current-month {
  @apply text-primaryText dark:text-dark-primaryText border-light dark:border-dark-light -mx-3 flex h-[60px] items-center justify-center border-b px-2 text-xl font-semibold leading-6;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__week {
  @apply flex justify-around py-0.5;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day-names {
  @apply text-tertiary m-0 flex justify-evenly gap-x-1 pb-1 text-center text-base;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day-name {
  @apply text-tertiary m-0 mt-2 flex h-8 w-8 items-center justify-center rounded-full;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__navigation {
  @apply absolute top-4;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__navigation-icon {
  @apply dark:text-dark-primaryText text-primaryText;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day {
  @apply text-primaryText dark:text-dark-primaryText hover:bg-buttons-primary-bg-hover hover:text-buttons-primary-text m-0 flex h-11 w-11 items-center justify-center rounded text-base leading-loose transition;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__day--outside-month {
  @apply text-placeholder dark:text-dark-placeholder;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day--in-range {
  @apply bg-buttons-primary-bg-normal dark:bg-dark-buttons-primary-bg-normal text-buttons-primary-text;
}

div[data-eco-component="advanced-datepicker"]
  .react-datepicker__day--in-selecting-range {
  @apply bg-buttons-secondary-bg-active dark:bg-dark-buttons-secondary-bg-active text-primaryText dark:text-dark-primaryText;
}

div[data-eco-component="advanced-datepicker"] .react-datepicker__day--selected {
  @apply bg-buttons-primary-bg-normal dark:bg-dark-buttons-primary-bg-normal text-buttons-primary-text;
}
