.legend-carousel .carousel-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
}

.legend-carousel .carousel-container .slick-prev,
.legend-carousel .carousel-container .slick-next {
  display: none !important;
}
