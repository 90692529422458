/* React Datepicker style overrides */

.stratos-datepicker .react-datepicker {
  @apply border-light dark:border-dark-light;
}

.stratos-datepicker .react-datepicker__input-container input {
  @apply border-interactive dark:border-dark-interactive bg-container-light dark:bg-dark-container-light text-primaryText dark:text-dark-primaryText focus-within:border-focus dark:focus-within:ring-dark-focus dark:focus-within:border-dark-focus focus-within:ring-focus block h-11 w-full rounded-md border px-3 py-1 font-normal;
}

.stratos-datepicker.gray .react-datepicker__input-container input {
  @apply bg-container-baseContainer dark:bg-dark-container-baseContainer;
}

.stratos-datepicker.invalid .react-datepicker__input-container input {
  @apply border-error focus:border-error focus:ring-error dark:border-dark-error dark:focus:ring-dark-error;
}

.stratos-datepicker .react-datepicker__input-container input:read-only {
  @apply border-dark dark:border-dark-dark;
}

.stratos-datepicker .react-datepicker__input-container input:disabled {
  @apply border-dark dark:border-dark-dark text-secondary dark:text-dark-secondary opacity-50;
}

.stratos-datepicker .react-datepicker {
  @apply border-gray-300 shadow;
}

.stratos-datepicker .react-datepicker__header {
  @apply bg-container-light dark:bg-dark-container-light border-b-0;
}

.stratos-datepicker .react-datepicker__day-name {
  @apply text-gray-400;
}

.stratos-datepicker .react-datepicker__triangle {
  @apply hidden;
}

.stratos-datepicker .react-datepicker-popper[data-placement^="bottom"] {
  @apply border-light dark:border-dark-light pt-1.5;
}

.stratos-datepicker .react-datepicker__navigation {
  @apply bg-container-light dark:bg-dark-container-light rounded border-2 border-gray-300 transition hover:bg-gray-200;
}

.stratos-datepicker .react-datepicker__navigation-icon::before {
  @apply text-primaryText dark:text-dark-primaryText;
}

.stratos-datepicker .react-datepicker__navigation-icon--next {
  @apply left-0;
}

.stratos-datepicker .react-datepicker__navigation--next {
  @apply right-2;
}

.stratos-datepicker .react-datepicker__navigation-icon--previous {
  @apply right-0;
}

.stratos-datepicker .react-datepicker__navigation--previous {
  @apply left-2;
}

.stratos-datepicker .react-datepicker-left {
  @apply absolute left-0 right-auto top-11 transform-none !important;
}

.stratos-datepicker .react-datepicker-right {
  @apply absolute left-auto right-0 top-11 transform-none !important;
}

.stratos-datepicker .react-datepicker__month-container {
  @apply bg-container-light dark:bg-dark-container-light flex flex-col font-sans;
}

.stratos-datepicker .react-datepicker__month {
  @apply flex flex-col;
}

.stratos-datepicker .react-datepicker__current-month {
  @apply text-primaryText dark:text-dark-primaryText ml-2.5 text-lg font-semibold;
}

.stratos-datepicker .react-datepicker__week {
  @apply flex justify-around;
}

.stratos-datepicker .react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-400;
}

.stratos-datepicker .react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.stratos-datepicker .react-datepicker__navigation {
  @apply absolute top-2;
}

.stratos-datepicker .react-datepicker__day {
  @apply text-primaryText dark:text-dark-primaryText mb-1 flex h-8 w-8 items-center justify-center rounded py-1 text-sm leading-loose transition;
}

.stratos-datepicker .react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-40 hover:bg-transparent;
}

.stratos-datepicker .react-datepicker__day--outside-month {
  @apply text-tertiary dark:text-dark-tertiary;
}

.stratos-datepicker .react-datepicker__day--in-range {
  @apply bg-gray-300;
}

.stratos-datepicker .react-datepicker__day--in-selecting-range {
  @apply bg-blue-500 text-white;
}

.stratos-datepicker .react-datepicker__day--selected {
  @apply bg-blue-500 text-white hover:bg-blue-500 hover:text-white;
}

.stratos-datepicker .react-datepicker__day--keyboard-selected {
  @apply border-2 border-blue-800 bg-blue-400 text-white;
}

.stratos-datepicker .react-datepicker__day--range-start {
  @apply bg-blue-500 text-white;
}

.stratos-datepicker .react-datepicker__day--range-end {
  @apply bg-blue-500 text-white;
}
