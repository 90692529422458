.flyout-radio-container {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flyout-radio-container input {
  @apply absolute -z-10 opacity-0;
}

.eco-checkmark-flyout-radiogroup {
  @apply absolute left-9 top-1 h-5 w-5;
}

.flyout-radio-container
  input[type="radio"]:checked
  ~ .eco-checkmark-flyout-radiogroup {
  @apply rounded-full bg-blue-500;
}

.eco-checkmark-flyout-radiogroup:after {
  @apply absolute hidden content-[""];
}

.flyout-radio-container
  input[type="radio"]:checked
  ~ .eco-checkmark-flyout-radiogroup:after {
  @apply block;
}

.flyout-radio-container .eco-checkmark-flyout-radiogroup:after {
  left: 8px;
  top: 4px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
