.eco-slider-track,
.eco-slider-2thumb-track {
  @apply my-2 h-1 rounded-full;
}

.eco-slider-track-0 {
  @apply bg-brand-accent dark:bg-dark-brand-accent;
}

.eco-slider-track-1 {
  @apply bg-components-medium dark:bg-dark-components-medium;
}

.eco-slider-thumb {
  @apply bg-brand-white border-interactive dark:border-dark-interactive relative top-[2px] flex cursor-pointer items-center justify-center rounded-full border-2 p-1.5 text-sm font-semibold leading-none text-gray-700;
}

.eco-slider-thumb-active {
  @apply focus:ring-brand-white relative rounded-full focus:outline-none focus:ring-2;
}

.eco-slider-2thumb-track-0 {
  @apply bg-components-medium dark:bg-dark-components-medium;
}

.eco-slider-2thumb-track-1 {
  @apply bg-brand-accent dark:bg-dark-brand-accent;
}

.eco-slider-2thumb-track-2 {
  @apply bg-components-medium dark:bg-dark-components-medium;
}
