/* React Time Picker style overrides */

.stratos-timepicker .react-time-picker {
  @apply bg-container-light dark:bg-dark-container-light block rounded-md;
}

.stratos-timepicker.gray .react-time-picker {
  @apply bg-container-baseContainer dark:bg-dark-container-baseContainer;
}

.stratos-timepicker .react-time-picker__wrapper {
  @apply border-interactive dark:border-dark-interactive focus-within:border-focus focus-within:ring-focus dark:focus-within:ring-dark-focus dark:focus-within:border-dark-focus dark:focus-within:ring-dark-focus dark:border-dark-interactive h-11 w-full rounded-md border px-3 py-1 focus-within:ring-1;
}

.stratos-timepicker .react-time-picker__inputGroup__input {
  @apply text-primaryText dark:text-dark-primaryText rounded-md border-0 bg-transparent p-0 focus:ring-blue-500;
}

.stratos-timepicker
  .react-time-picker.react-time-picker--closed.react-time-picker--disabled
  .react-time-picker__wrapper {
  @apply border-dark dark:border-dark-dark opacity-50;
}

.stratos-timepicker
  .react-time-picker.react-time-picker--closed.react-time-picker--disabled
  .react-time-picker__wrapper
  .react-time-picker__inputGroup
  > input:disabled {
  @apply opacity-50;
}

.stratos-timepicker
  .react-time-picker.react-time-picker--closed.react-time-picker--disabled
  .react-time-picker__wrapper
  .react-time-picker__inputGroup
  > select:disabled {
  @apply opacity-50;
}

.stratos-timepicker .react-time-picker__inputGroup__leadingZero {
  @apply text-primaryText dark:text-dark-primaryText;
}

.stratos-timepicker
  .react-time-picker__inputGroup__input:disabled
  ~ .react-time-picker__inputGroup__leadingZero {
  @apply leading-5 opacity-50;
}

.stratos-timepicker .react-time-picker__inputGroup__input--hasLeadingZero {
  @apply m-0;
}

.stratos-timepicker .react-time-picker__inputGroup {
  @apply flex items-center;
}

.stratos-timepicker .react-time-picker__inputGroup__input::placeholder {
  @apply text-primaryText dark:text-dark-primaryText;
}

.stratos-timepicker .react-time-picker--disabled {
  @apply bg-transparent;
}

.stratos-timepicker .react-time-picker__clear-button {
  @apply text-primaryText dark:text-dark-primaryText py-0 pr-0;
}
.stratos-timepicker
  .react-time-picker__inputGroup
  .react-time-picker__inputGroup__divider {
  @apply text-primaryText dark:text-dark-primaryText mx-px;
}

.stratos-timepicker .react-time-picker__inputGroup__amPm {
  @apply text-primaryText dark:text-dark-primaryText;
}

.stratos-timepicker
  .react-time-picker.react-time-picker--closed.react-time-picker--disabled
  .react-time-picker__clear-button {
  @apply opacity-50;
}

.stratos-timepicker.invalid .react-time-picker__wrapper {
  @apply bg-alerts-background-error/10 dark:bg-dark-alerts-background-error/10 border-error;
}
