.stratos-table tbody > tr:hover td {
  @apply bg-buttons-tertiary-bg-hover dark:bg-dark-buttons-tertiary-bg-hover !important;
}

.stratos-table tbody > tr td:first-child {
  @apply border-light dark:border-dark-light border-l;
}

.stratos-table tbody > tr td:last-child {
  @apply border-light dark:border-dark-light border-r;
}

.stratos-table tbody > tr:last-child td {
  @apply border-light dark:border-dark-light rounded-b-md border-b;
}
