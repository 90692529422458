/* React Time Picker style overrides */

.date-range-picker-time .react-time-picker {
  @apply block;
}

.date-range-picker-time .react-time-picker__wrapper {
  @apply mt-1 w-full rounded-md border-gray-500 px-2 py-1 shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm;
}

.date-range-picker-time .react-time-picker__inputGroup__input {
  @apply appearance-none rounded-sm border-0 bg-transparent p-0 text-base focus:ring-blue-500;
}

.date-range-picker-time .react-time-picker__inputGroup__leadingZero {
  @apply text-base leading-4;
}

.date-range-picker-time .react-time-picker__inputGroup__input--hasLeadingZero {
  @apply m-0;
}

.date-range-picker-time .react-time-picker__inputGroup {
  @apply flex items-center;
}

.date-range-picker-time .react-time-picker--disabled {
  @apply bg-transparent;
}
