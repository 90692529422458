.eco-timeline-slider-track,
.eco-timeline-slider-2thumb-track {
  @apply my-2 h-2 rounded-full;
}

.eco-timeline-slider-track-0 {
  @apply bg-blue-600;
}

.eco-timeline-slider-track-1 {
  @apply bg-gray-200;
}

.eco-timeline-slider-thumb {
  @apply relative top-[3px] flex cursor-pointer items-center justify-center rounded-full border-2 border-blue-600 bg-blue-600 p-1.5 text-sm font-semibold leading-none text-white;
}

.eco-timeline-slider-thumb-active {
  @apply relative rounded-full focus:outline-none focus:ring-2 focus:ring-blue-200;
}

.eco-timeline-slider-2thumb-track-0 {
  @apply bg-gray-200;
}

.eco-timeline-slider-2thumb-track-1 {
  @apply bg-blue-600;
}

.eco-timeline-slider-2thumb-track-2 {
  @apply bg-gray-200;
}

.eco-timeline-slider-mark {
  @apply top-6 ml-1.5 w-0.5 cursor-pointer bg-gray-200;
}

.eco-timeline-slider-mark-nolabel {
  @apply top-2 ml-0 h-2 w-0.5 cursor-pointer bg-gray-200;
}

.hide .eco-timeline-slider-thumb {
  @apply opacity-0;
}

.hide .eco-timeline-slider-track {
  @apply opacity-0;
}
