.flyoutmenu-checkbox-container {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.flyoutmenu-checkbox-container input {
  @apply absolute -z-10 opacity-0;
}

.eco-checkmark-flyout-menu {
  @apply absolute h-4 w-4;
}

.flyoutmenu-checkbox-container input:checked ~ .eco-checkmark-flyout-menu {
  @apply rounded-full bg-blue-500;
}

.eco-checkmark-flyout-menu:after {
  @apply absolute hidden content-[""];
}

.flyoutmenu-checkbox-container
  input:checked
  ~ .eco-checkmark-flyout-menu:after {
  @apply block;
}

.flyoutmenu-checkbox-container .eco-checkmark-flyout-menu:after {
  left: 6px;
  top: 4px;
  width: 4px;
  height: 7px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
